import {
  type PaymentManagePayeesPageParams,
  type PaymentManagePayeesPageTab,
} from '@webapp/pages/PaymentManagePayeesPage'
import { type PageUrlsBuilders } from './types'

/**
 * Rules:
 *
 * Each key within this object must be named the same as the
 * page that will be rendered.
 */
export const PageUrls: PageUrlsBuilders = {
  BillApprovalDetailsPage: ({ uuid }) => `/bills/approvals/${uuid}`,
  BillApprovalListPage: () => `/#/bills/approvals`,
  BillCreatePage: ({ uuid = '' }) => `/bills/create/${uuid}`,
  BillListPage: ({ status }) => `/bills/requests/${status}`,
  BillRequestDetailsPage: ({ uuid }) => `/bills/requests/${uuid}`,
  UnbilledItemsPage: ({ status }) => `/unbilled/items/${status}`,
  ContractCreatePage: ({ uuid = '' }) => `/contracts/create/${uuid}`,
  ContractListPage: ({ status }) => `/contracts/${status}`,
  ContractDetailsPage: ({ uuid }) => `/contracts/${uuid}`,
  ContractEditDetailsPage: ({ uuid }) => `/contracts/${uuid}/edit`,
  QboPage: () => '/integrations/qbo',
  SageIntacctPage: () => '/integrations/sage-intacct',
  Dynamics365BCPage: () => '/integrations/dynamics-365-business-central',
  DashboardPage: () => '/',
  IntegrationsPage: () => '/integrations',
  LegacyNetsuiteSyncLogsPage: () => '/integrations/netsuite',
  LegacyQuickBooksDesktopPage: () => '/#/integrations/quickbooks',
  LegacySlackPage: () => '/#/integrations/slack',
  NetsuitePage: () => '/integrations/netsuite',
  NetsuiteBillsPage: () => '/integrations/netsuite/bills',
  OrderStatusPage: ({ status }) => `/orders/requests/${status}`,
  OrderDetailsPage: ({ uuid }) => `/orders/${uuid}`,
  LegacyOrderDetailsPage: ({ uuid }) => `/#/orders/${uuid}`,
  LegacyOrderRequestPage: () => '/#/orders/request',
  OrderApprovalPage: ({ status }) => `/#/orders/approvals/${status}`,
  PaymentPayBillsPage: ({ status }) => `/payments/pay-bills/${status}`,
  PaymentPreparePage: ({ uuid }) => `/payments/prepare/${uuid}`,
  PaymentListPage: () => '/payments',
  PaymentDetailsPage: ({ uuid }) => `/payments/${uuid}`,
  PaymentApprovalListPage: ({ status }) => `/payments/approvals/${status}`,
  PaymentApprovalDetailsPage: ({ uuid }) => `/payments/approvals/${uuid}`,
  PaymentManagePayeesPage: <
    T extends PaymentManagePayeesPageTab = PaymentManagePayeesPageTab,
  >({
    type,
    id,
    tab,
  }: PaymentManagePayeesPageParams<T>) =>
    `/payments/manage-payees/${type}/${id}/${tab || ''}`,
  FinancialAccountPage: () => `/financial-account`,
  PaymentFinancialAccountPage: () => `/payments/financial-account`,
  ProcurementPage: () => '/procurement',
  ProcurifyApiPage: () => '/integrations/api',
  PurchaseOrderListPage: ({ status }) => `/purchase-orders/${status}`,
  PurchaseOrderCreatePoPage: ({ vendorId }) =>
    `/purchase-orders/create/${vendorId}`,
  PurchaseOrderDetailsPage: ({ uuid, tab }) =>
    `/purchase-orders/${uuid}${tab ? `/${tab}` : ``}`,
  PurchaseOrderDetailsPreviousVersionPage: ({ uuid, version }) =>
    `/purchase-orders/${uuid}/version/${version}`,
  PurchaseOrderRevisePoPage: ({ uuid }) => `/purchase-orders/${uuid}/revise`,
  PurchaseOrderDetailEmailLogPage: ({ uuid }) =>
    `/#/purchase/order/details/${uuid}/email-log`,
  PurchaseOrderCreate: () => `/#/purchase/order/create`,
  PunchOutSuppliersPage: ({ supplier }) => `/settings/punchout/${supplier}`,
  PunchOutGroupPage: ({ supplier, group }) =>
    `/settings/punchout/${supplier}/group/${group}`,
  ReceiveDetailsPage: ({ uuid }) => `/receive/${uuid}`,
  ReceiveListPage: ({ status }) => `/receive/${status}`,
  SettingsPage: () => '/settings',
  SettingsBillFormPage: () => '/settings/customize-bill-form',
  SettingsExpenseFormPage: () => '/settings/customize-expense-form',
  SettingsManageFinancialAccountPage: () =>
    '/settings/manage-financial-account',
  SettingsManageBankAccountsPage: () => '/settings/manage-bank-accounts',
  BudgetCategoryManagementRouter: () => '/settings/budget-categories',
  BudgetCategoryPage: () => '/settings/budget-categories',
  BudgetCategoryCreatePage: () => '/settings/budget-categories/create',
  BudgetCategoryEditPage: ({ uuid }) =>
    `/settings/budget-categories/edit/${uuid}`,
  BudgetsManagementRouter: () => '/settings/budgets',
  BudgetsPage: () => '/settings/budgets',
  BudgetsCreatePage: () => '/settings/budgets/create',
  BudgetsEditPage: ({ uuid }) => `/settings/budgets/edit/${uuid}`,
  SpendingCardApprovalListPage: ({ tab }) => `/spending-card/approvals/${tab}`,
  SpendingCardApproveFundRequestPage: ({ uuid }) =>
    `/spending-card/approvals/fund/${uuid}`,
  SpendingCardApproveVirtualCardRequestPage: ({ uuid }) =>
    `/spending-card/approvals/virtual-card/${uuid}`,
  SpendingCardCustomFieldsSettingsPage: () =>
    `/settings/spending-card-custom-fields`,
  SpendingCardManagementListPage: ({ tab }) =>
    `/spending-card/card-management/${tab}`,
  SpendingCardManagementFundDetailsPage: ({ card, fund }) =>
    `/spending-card/card-management/${card}/fund/${fund}`,
  SpendingCardReconcileTransactionsPage: () => `/spending-card/reconciliation`,
  SpendingCardRequestVirtualCardPage: () =>
    `/spending-card/virtual-cards/request`,
  SpendingCardVirtualCardsPage: ({ tab }) =>
    `/spending-card/virtual-cards/${tab}`,
  SpendingCardVirtualCardDetailsPage: ({ uuid }) =>
    `/spending-card/virtual-cards/card/${uuid}`,
  SpendingCardVirtualCardRequestDetailsPage: ({ uuid }) =>
    `/spending-card/virtual-cards/request/${uuid}`,
  SpendingCardVirtualCardTopUpDetailsPage: ({ card, uuid }) =>
    `/spending-card/virtual-cards/card/${card}/top-up-request/${uuid}`,
  SpendingCardPhysicalCardPage: ({ tab }) =>
    `/spending-card/physical-card/${tab}`,
  SpendingCardFundRequestDetailsPage: ({ uuid }) =>
    `/spending-card/physical-card/fund-request/${uuid}`,
  SpendingCardFundDetailsPage: ({ uuid }) =>
    `/spending-card/physical-card/fund/${uuid}`,
  SpendingCardCreateFundRequestPage: () =>
    `/spending-card/physical-card/request-fund`,
  SpendingCardTransactionListPage: ({ tab }) =>
    `/spending-card/transactions/${tab}`,
  UserProfilePage: ({ tab }) => `/profile/${tab}`,
  InstanceManagementPage: () => '/instance-management',
  SpendAnalyticsPage: () => '/analytics/spend-insights',
  VendorApprovalListPage: ({ status }) => `/vendor/approvals/${status}`,
  VendorApprovalDetailsPage: ({ id }) => `/vendor/approvals/${id}`,
  SettingsSupportAccessPage: () => '/settings/support-access',
  ContractSettingsPage: () => '/settings/contracts',
  ProcurifyPaymentsPage: () => '/procurify-payments',
  SettingsMFAPreferencesPage: () => '/settings/mfa',
  SettingsApprovalRoutingPage: ({ tab }) => `/settings/approval-routing/${tab}`,
  ExpenseStatusPage: ({ status }) => `/expenses/requests/${status}`,
  ExpenseDetailPage: ({ uuid }) => `/#/expenses/${uuid}`,
  ExpenseRequestPage: () => `/#/expenses/request`,
  NoPermissionsPage: () => `/permissions-pending`,
  UserDetailsPage: ({ userId }) => `/settings/users/${userId}`,
  SettingsManageUsersPage: ({ status }) => `/settings/users/list/${status}`,
  LegacyApprovalDelegationHistoryPage: () =>
    `/#/settings/user/delegation-history`,
  SettingsManageRolesPermissionsPage: () => `/settings/roles-permissions`,
  LegacyUserCSVExportHistoryPage: () => '/#/settings/exportcsv/requested/users',
  UnbilledExpenseItemsPage: () => `/#/ap/unbilled/expenses`,
  OrderRequestPage: () => `/orders/request`,
  CardholderAccountPage: () => `/spending-card/cardholder`,
  CardholderAccountEditPage: () => `/spending-card/cardholder/edit`,
}
